import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue')
    },
    {
        path: '/:slug/overview',
        name: 'section-overview',
        component: () => import('../views/sections/OverviewView.vue')
    },
    {
        path: '/:slug/law',
        name: 'section-law',
        component: () => import('../views/sections/LawView.vue')
    },
    {
        path: '/:slug/promise',
        name: 'section-promise',
        component: () => import('../views/sections/PromiseView.vue')
    },
    {
        path: '/:slug/activity-badges',
        name: 'section-activity-badges',
        component: () => import('../views/sections/ActivityBadgesView.vue')
    },
    {
        path: '/update-required',
        name: 'update-required',
        component: () => import('../views/UpdateRequiredView.vue')
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
