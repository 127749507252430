<template>
    <!-- Top bar -->
    <div class="absolute w-full flex items-center justify-between top-0 h-16 z-20 px-6">
        <div class="w-20 flex items-center">
            <IconBars v-if="!disableMenu" class="h-7 mr-6" fill="#ffffff" @click="openMenu"/>
            <IconArrowLeft v-if="!isRootRoute && !disableMenu" @click="goBack" class="h-7" fill="#ffffff"/>
        </div>
        <div>
            <IconWhite class="block mx-auto h-10"/>
        </div>
    </div>

    <!-- Menu -->
    <div :class="`fixed top-0 w-full h-full bg-black z-30 ${menuOpen ? 'opacity-30 visible' : 'opacity-0 invisible'} transition-all duration-500`"></div>
    <div ref="menu" :style="{ bottom: `${calculateHeight()}px` }" :class="`fixed w-full py-8 px-6 bg-white rounded-t-3xl z-40 ${menuOpen ? 'visible' : 'invisible'} transition-all duration-500`">
        <div class="absolute top-3 left-1/2 -translate-x-1/2 h-1 w-8 rounded bg-gray-400"></div>

        <div class="flex justify-between items-center mb-3">
            <h1 class="text-2xl font-google-sans-medium font-medium">Menu</h1>
            <div>
                <IconClose class="h-7 fill-gray-600" @click="closeMenu"/>
            </div>
        </div>

        <div>
            <router-link to="" class="">
                <div class="">
                    
                </div>
                <h1 class="text-base font-google-sans-medium font-medium">Home</h1>
            </router-link>
        </div>
    </div>
</template>

<script>
import IconBars from '@/components/icons/IconBars.vue';
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';
import IconWhite from '@/components/IconWhite.vue';
import IconClose from '@/components/icons/IconClose.vue';

export default {
    name: 'NavigationMenu',
    components: {
        IconArrowLeft,
        IconBars,
        IconWhite,
        IconClose,
    },
    props: {
        disableMenu: Boolean,
    },
    data() {
        return {
            menuOpen: false,
        };
    },
    computed: {
        isRootRoute() {
            return this.$route.path === '/';
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        closeMenu() {
            this.menuOpen = false;
        },
        openMenu() {
            this.menuOpen = true;
        },
        calculateHeight() {
            if (this.menuOpen) {
                return 0;
            } else {
                const element = this.$refs.menu;
                if (element) {
                    const height = element.clientHeight;
                    return -height;
                }
                return 0;
            }
        },
    },
}
</script>