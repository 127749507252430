<template>
    <div class="h-full w-full">
        <!-- Navigation Menu -->
        <NavigationMenu :disableMenu="headerOptions.disableMenu"/>

        <!-- Header -->
        <div :class="`relative z-0 w-full transition-all duration-500 pb-6 ${headerOptions.backgroundColour} ${headerOptions.height} overflow-hidden`">
            <img v-if="headerOptions.image" class="absolute top-0 left-0 opacity-20 object-cover animate-image-fade duration-500" :src="require(`@/assets/images/${headerOptions.image}`)" :alt="headerOptions.title.text">
            <div v-if="headerOptions.title || headerOptions.organisations" class="absolute bottom-6 w-full p-6 animate-fade duration-500">
                <h2 v-if="headerOptions.organisations" :class="`text-sm tracking-wide font-google-sans-regular uppercase opacity-80 ${headerOptions.organisations.colour}`">{{ headerOptions.organisations.text }}</h2>
                <h1 v-if="headerOptions.title" :class="`text-3xl tracking-wide font-google-sans-medium ${headerOptions.title.colour}`">{{ headerOptions.title.text }}</h1>
            </div>
        </div>

        <router-view @updateHeaderOptions="updateHeaderOptions"/>
    </div>
</template>

<script>
import NavigationMenu from '@/components/NavigationMenu.vue';

export default {
    components: {
        NavigationMenu
    },
    data() {
        return {
            headerOptions: {
                title: null,
                organisations: null,
                image: null,
                disableMenu: false,
                backgroundColour: 'bg-sky-600',
                height: 'h-24'
            }
        };
    },
    methods: {
        updateHeaderOptions(options) {
            this.headerOptions = { ...this.headerOptions, ...options };
        },
    },
};
</script>

<style>
    @import '@/style.css';
</style>