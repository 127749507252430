<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 97">
        <g transform="matrix(1.037999979654948,0,0,1.037999979654948,-9.353002021153713,-2.6099980309804494)" stroke="none" fill="#FFFFFF">
            <path d="M31.112 39.094v21.812L49.999 71.81l18.89-10.903V39.094l-18.89-10.905-18.887 10.905zm29.813 17.214l-10.926 6.307-10.924-6.307V43.692l10.924-6.308 10.926 6.308v12.616z"/>
            <path d="M49.999 3.29L9.547 26.646v46.709L49.999 96.71l40.454-23.355V26.646L49.999 3.29zM82.19 68.584L49.999 87.169 17.811 68.584v-37.17l32.188-18.585L82.19 31.414v37.17z"/>
            <path d="M20.368 32.89v34.218l29.631 17.108 29.634-17.108V32.89L49.999 15.782 20.368 32.89zm51.078 29.493l-21.447 12.38-21.444-12.38V37.618l21.444-12.382 21.447 12.382v24.765z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'IconWhite',
}
</script>